<template>
  <b-row>
    <b-col md="6">
      <b-card title="Report 1">
        <b-card-body>
          <bar-chart-component
            :height="400"
            :data="data"
          />
        </b-card-body>
      </b-card>
    </b-col>
    <b-col md="6">
      <b-card title="Report 2">
        <b-card-body>
          <polar-area-component
            :height="400"
            :data="data2"
          />
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BCard, BCardBody,
} from 'bootstrap-vue'
import BarChartComponent from './components/BarChartComponent.vue'
import PolarAreaComponent from './components/PolarAreaComponent.vue'

export default {
  components: {
    PolarAreaComponent,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BarChartComponent,
  },
  setup() {
    return {
      data: {
        labels: [
          35, 36, 37, 38, 39,
        ],
        datasets: [{
          label: 'Test 1',
          data: [
            0, 0, 0, 1, 0,
          ],
          backgroundColor: '#69ef74',
          borderColor: '#69ef74',
        }, {
          label: 'Test 2',
          data: [
            0, 0, 2, 1, 0,
          ],
          backgroundColor: '#2558db',
          borderColor: '#2558db',
        }],
      },
      data2: {
        labels: ["Test 1","Test 2"],
        datasets:[{"data":[1,3],"backgroundColor":["#cc1e9a","#71f943","#11ad5f","#2ec938","#23a315"]}]
      },
    }
  },
}
</script>

<style>

</style>
